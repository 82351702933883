.hero {
  background-color: $primary-700;
  white-space: pre-line;
}

.hero__content {
  @include center-vertically;
  font-family: Inter;
  min-height: $banner-min-height;
  max-height: $hero-max-height;

  .display-1 {
    @extend .py-3;
    color: $white;
    max-width: 70%;
  }
  div {
    min-height: $banner-min-height;
    max-height: $hero-max-height;
    height: 100%;
  }
  .hero__image {
    min-height: $banner-min-height;
    max-height: $hero-max-height;
    height: 100%;
  }
  .highlighted {
    color: $accent-b;
  }
}

@include media-breakpoint-down(md) {
  .hero {
    min-height: 250px;
    max-height: 400px;

    .display-1 {
      max-width: 100%;
    }
  }
}
