@import '~@edx/brand/paragon/fonts.scss';
@import '~@edx/brand/paragon/variables.scss';
@import '@openedx/paragon/scss/core/core.scss';
@import '~@edx/brand/paragon/overrides.scss';

@import '~@edx/frontend-component-header/dist/index';
@import '~@edx/frontend-component-footer/dist/footer';

@import '~@edx/frontend-enterprise-catalog-search';

// Variables and mixins must come before other styles
@import './styles/variables';
@import './styles/mixins';

@import './styles/site_header';
@import './components/catalogs/styles';
@import './components/hero/Hero';
@import 'components/catalogSearchResults/associatedComponents/downloadCsvButton/DownloadCsvButton';
@import 'components/catalogSelectionDeck/CatalogSelectionDeck';
@import 'components/aiCuration/xpertResultCard/XpertResultCard';

.page-width {
  max-width: 1350px;
}

.padded-catalog.badge {
  margin: 4px;
}

.bright.badge {
  color: $black;
}

.program.badge {
  font-family: $font-family-monospace;
}

.course-card .pgn__card-logo-cap {
  object-fit: scale-down;
  object-position: center center;
}
