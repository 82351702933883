input[type='range'] {
  accent-color: yellow;
}

input[type='range']::-webkit-slider-runnable-track {
  width: 300px;
  height: 6px;
  background: #fff;
  border: none;
  border-radius: 3px;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  margin-top: -4px;
}

input[type='range']:focus::-webkit-slider-runnable-track {
  background: #ffff;
}
