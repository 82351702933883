.full-body {
  overflow-x: hidden; /* avoid horizontal scrollbar */
}

.padded-body {
  margin: 180px 0 0 24px;
  padding: 0em 0.9em 0em 0em;
}

.course-info-title {
  position: relative;
  width: 70% !important;
  left: 0% !important;
  flex-shrink: 1;
  letter-spacing: -0.02em;
}

.course-info-partner {
  position: relative;
  left: 0% !important;
}

.course-info-hero {
  background-position: center;
  width: 1140px !important;
  height: 300px !important;
  @media only screen and (max-width: map-get($grid-breakpoints, 'md')) {
    width: 800px !important;
  }
  @media only screen and (max-width: map-get($grid-breakpoints, 'sm')) {
    width: 500px !important;
  }
}

.landing-page-download {
  position: absolute;
  top: -60px;
  right: 0;
  @media only screen and (max-width: map-get($grid-breakpoints, 'xl')) {
    position: relative;
    top: 0;
  }
}

.preview-title {
  justify-content: space-between;
  display: flex;
  clear: right;
  align-items: center;
}

.partner-logo-thumbnail {
  position: absolute;
  z-index: 1;
  left: 25px;
  top: 230px;
  max-height: 90px;
  max-width: 200px;
  background-color: $light-300;
  box-shadow: $box-shadow;
}

.xpert-logo {
  max-height: 65px;
  cursor: pointer;
}

.suggestions {
  z-index: 2 !important;
}

.card {
  width: 300px;
  line-height: 24px;
}

.course-card {
  width: 300px;
  line-height: 24px;
  .pgn__card-section {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .pgn__card-logo-cap {
    background: $white;
  }
}

.banner {
  display: flex;
}

.banner-section {
  line-height: 24px;
  color: $primary-300;
}

.slash {
  font-weight: 700;
  font-size: 60px;
  color: $gray-100;
}

.banner-subtitle {
  margin-left: 30px;
}

.course-info-modal {
  position: absolute !important;
  top: 10%;
}

.pgn__modal-close-container {
  background-color: $gray-100 !important;
  border-radius: 50% !important;
  border: solid 1px $black;
}

.course-info-skills {
  background-color: $light-300;
}

.course-info-skills-list {
  display: block;
  padding-inline-start: 0;
  font-size: medium;

  li {
    display: inline-block;
  }
  li:not(:last-child):after {
    content: '\2022';
    margin: 0 10px;
  }
}

.course-info-modal-course-thumbnail {
  width: 9vw;
  max-width: 230px;
  min-width: 170px;
  height: 12vw;
  max-height: 100px;
  min-height: 90px;
}

@media only screen and (max-width: map-get($grid-breakpoints, 'md')) {
  .slash {
    display: none;
  }
  .banner {
    display: block;
  }
  .banner-section {
    margin-bottom: 16px;
  }
  .banner-subtitle {
    margin-left: 0px;
  }
}
